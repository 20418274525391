<template>
  <div class="js-common-wrap js-creater-user">
    <div class="js-common-head">
      <div>
        <el-button
          class="back-btn"
          @click="$goBack"
          icon="el-icon-arrow-left"
          size="small"
          type="primary"
          plain
        ></el-button>
        <label class="label">{{ type ? "修改事件" : "创建事件" }}</label>
      </div>
    </div>
    <div class="js-common-content">
      <div class="js-creater-user-content">
        <ul>
          <li>
            <label class="common-tip"><i>*</i>地点:</label>
            <el-input
              type="text"
              v-model="params.address"
              size="small"
            ></el-input>
          </li>
          <li>
            <label class="common-tip"><i>*</i>事件内容：</label>
            <el-input
              v-model="params.content"
              size="small"
              maxlength="30"
              show-word-limit
              type="textarea"
            ></el-input>
          </li>
          <li></li>
          <li>
            <label class="common-tip"><i>*</i>事件名称</label>
            <el-input v-model="params.name" size="small"></el-input>
          </li>
          <li>
            <label class="common-tip" v-show="type">紧急程度：</label>
            <div>
              <el-select
                v-model="params.urgencyRank"
                placeholder="请选择"
                size="small"
                clearable
                v-show="type"
              >
                <el-option
                  v-for="item in urgencylevel"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </li>
          <li>
            <label class="common-tip" v-show="type">事件状态：</label>
            <div>
              <el-select
                v-model="params.status"
                placeholder="请选择"
                size="small"
                clearable
                v-show="type"
              >
                <el-option
                  v-for="item in eventstatus"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </li>
          <li>
            <label class="common-tip" v-show="type" v-if="this.params.type == 2"
              ><i>*</i> 不处置原因</label
            >
            <el-input
              v-model="params.ignoreReason"
              size="small"
              maxlength="30"
              show-word-limit
              type="textarea"
              v-if="this.params.type == 2"
            ></el-input>
          </li>
          <li>
            <label class="common-tip"><i>*</i>上报人：</label>
            <div>
              <el-select
                v-model="params.reportUserId"
                placeholder="请选择"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in reportArr"
                  :key="item.userId"
                  :label="item.realName"
                  :value="item.userId"
                ></el-option>
              </el-select>
            </div>
          </li>

          <li>
            <label class="common-tip"><i>*</i>发生时间：</label>
            <div>
              <el-date-picker
                v-model="params.happenTime"
                type="datetime"
                placeholder="选择日期时间"
                value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
            </div>
          </li>
          <li>
            <el-button size="small" type="primary" @click="handleAttManage"
              >选择附件</el-button
            >
          </li>
          <li class="item" v-for="file in currentFileList" :key="file.id">
            <video
              v-if="isVideo(file.fullUrl)"
              :src="file.fullUrl"
              autoplay="true"
              loop="true"
              class="video"
            ></video>
            <img
              v-else
              :src="file.fullUrl"
              alt=""
              style="width: 200px; margin: 5px; height: 150px"
            />
            {{ file.name }}
            <i class="el-icon-close" @click="deleteImage(file)"></i>
          </li>
        </ul>
        <div class="btns">
          <el-button @click="handleCancel" size="small">取 消</el-button>
          <el-button type="primary" @click="handleConfirm" size="small"
            >确 定</el-button
          >
        </div>
      </div>
    </div>
    <att-manage
      v-model="attDialogVisible"
      :fileList="fileList"
      @attManageSearch="attManageSearch"
      @receiveFileUrl="receiveFileUrl"
    ></att-manage>
  </div>
</template>
<script>
// import { mapGetters } from 'vuex'
import AttManage from "@/components/EventAttachManagement";

export default {
  name: "",
  components: {
    AttManage,
  },
  data() {
    return {
      params: {
        address: "",
        content: "",
        happenTime: "",
        id: "",
        ignoreReason: "",
        name: "",
        remark: "",
        reportUserId: "",
        status: "",
        attachIds: [],
        taskId: "",
        type: "",
        urgencyRank: "",
      },
      from: "",
      number: "",
      seqnum: "",
      attDialogVisible: false,
      to: "",
      type: "",
      uid: "",
      ifback: false,
      action: "",
      version: "",
      currentEvent: "",
      reportArr: [],
      fileList: [],
      fileIdArr: [],
      options: [],
      currentFileList: [],
      urgencylevel: [
        {
          value: 0,
          label: "最高",
        },
        {
          value: 1,
          label: "☆",
        },
        {
          value: 2,
          label: "☆☆",
        },
        {
          value: 3,
          label: "☆☆☆",
        },
        {
          value: 4,
          label: "☆☆☆☆",
        },
        {
          value: 5,
          label: "☆☆☆☆☆",
        },
        {
          value: 6,
          label: "☆☆☆☆☆☆",
        },
        {
          value: 7,
          label: "☆☆☆☆☆☆☆",
        },
        {
          value: 8,
          label: "☆☆☆☆☆☆☆☆",
        },
        {
          value: 9,
          label: "最低",
        },
      ],
      eventstatus: [
        {
          value: 0,
          label: "待确认",
        },
        {
          value: 1,
          label: "已确认",
        },
        {
          value: 2,
          label: "不处置",
        },
        {
          value: 3,
          label: "已关闭",
        },
        {
          defaultvalue: 0,
        },
      ],
    };
  },
  mounted() {
    // 获取select基准数据
    this.getReporter();
    // 编辑时获取数据
    if (this.$route.query.type) {
      this.fetchItem();
    }else{
      // 获取文件列表
      this.getcurrentFileList();
    }
  },
  methods: {
    // 获取当前信息
    fetchItem() {
      const params = JSON.parse(this.$route.query.options);
      this.$axios({
        method: "get",
        url: `api/csp/task/v1/taskEvent/${params.id}`,
      }).then(({ data }) => {
        if (!data.status) {
          this.params = data.data;
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
        this.getcurrentFileList();
      });
    },
    //获取执行人
    getReporter() {
      this.$axios({
        method: "get",
        url: `api/csp/task/v1/taskExecutor/users`,
      }).then((response) => {
        let data = response.data;
        if (!data.status) {
          this.reportArr = data.data;
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },

    //获取当前子任务附件表
    getcurrentFileList() {
      for (let i of this.params.attachIds) {
        this.$axios({
          method: "get",
          url: `api/csp/task/v1/taskAttach/${i}`,
        }).then((response) => {
          let data = response.data;
          if (!data.status) {
            this.currentFileList.push(data.data);
          } else {
            this.$message({
              type: "error",
              message: data.msg,
              duration: 1000,
            });
          }
        });
      }
    },

    //删除子任务附件
    deleteImage(file) {
      console.log("deleteImage");
      this.currentFileList = this.currentFileList.filter(
        (item) => item.id != file.id
      );
      let newids = [];
      for (let i of this.currentFileList) {
        newids.push(i.id);
      }
      this.params.attachIds = newids;
      console.log(this.currentFileList);
      console.log(this.params.attachIds);
    },

    //附件对话框
    handleAttManage() {
      this.attDialogVisible = true;
      this.getFileList({
        name: "",
        type: "",
      });
      console.log(this.params.attachIds);
    },

    //获取附件分页
    getFileList(params) {
      this.$axios({
        method: "get",
        url: "api/csp/task/v1/taskAttach/page",
        params: {
          page: 1,
          perPage: 20,
        },
      }).then((response) => {
        console.log(params);
        let data = response.data;
        if (!data.status) {
          this.fileList = data.data.rows;
          this.fileList.forEach((item) => {
            item.isCur = false;
          });
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },

    //附件搜索
    attManageSearch(params) {
      this.getFileList(params);
    },

    //收到选取附件
    receiveFileUrl(file) {
      let ifsame = false;
      // this.params.attachIds.some(function(item){
      //   return item == file.id
      // })
      if (ifsame == true) {
        this.$message({
          type: "error",
          message: "您已经提交过相同文件",
          duration: 1000,
        });
      } else {
        this.currentFileList.push(file);
        this.params.attachIds.push(file.id);
      }
    },
    handleConfirm() {
      if(!this.params.address){
        this.$message.error('地点不能为空！')
        return
      }else if(!this.params.content){
        this.$message.error('事件内容不能为空！')
        return

      }else if(!this.params.name){
        this.$message.error('事件名称不能为空！')
        return
      }else if(!this.params.reportUserId){
        this.$message.error('请选择上报人！')
        return
      }else if(!this.params.happenTime){
        this.$message.error('请选择发生时间！')
        return
      }
      let method = "post",
        url = `api/csp/task/v1/taskEvent/`;

      // 修改
      if (this.$route.query.type) {
        method = "put";
        url = `api/csp/task/v1/taskEvent/${this.params.id}`;
      }
      this.$axios({
        method: method,
        url: url,
        data: {
          data: {
            address: this.params.address,
            content: this.params.content,
            happenTime: this.params.happenTime,
            id: this.params.id,
            ignoreReason: this.params.ignoreReason,
            name: this.params.name,
            remark: this.params.remark,
            reportUserId: this.params.reportUserId,
            status: this.params.status,
            attachIds: this.params.attachIds,
            taskId: this.params.taskId,
            type: this.params.type,
            urgencyRank: this.params.urgencyRank,
          },
        },
      }).then((response) => {
        let data = response.data;
        if (!data.status) {
          this.$goBack();
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },
    handleCancel() {
      this.$goBack();
    },
    isVideo(url) {
      const arr = url.split(".");
      return arr[arr.length - 1] === "mp4";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/createrUser.scss";

.video {
  width: 280px;
  height: 150px;
}
</style>
