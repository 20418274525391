var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "js-common-wrap js-creater-user" },
    [
      _c("div", { staticClass: "js-common-head" }, [
        _c(
          "div",
          [
            _c("el-button", {
              staticClass: "back-btn",
              attrs: {
                icon: "el-icon-arrow-left",
                size: "small",
                type: "primary",
                plain: "",
              },
              on: { click: _vm.$goBack },
            }),
            _c("label", { staticClass: "label" }, [
              _vm._v(_vm._s(_vm.type ? "修改事件" : "创建事件")),
            ]),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "js-common-content" }, [
        _c("div", { staticClass: "js-creater-user-content" }, [
          _c(
            "ul",
            [
              _c(
                "li",
                [
                  _vm._m(0),
                  _c("el-input", {
                    attrs: { type: "text", size: "small" },
                    model: {
                      value: _vm.params.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.params, "address", $$v)
                      },
                      expression: "params.address",
                    },
                  }),
                ],
                1
              ),
              _c(
                "li",
                [
                  _vm._m(1),
                  _c("el-input", {
                    attrs: {
                      size: "small",
                      maxlength: "30",
                      "show-word-limit": "",
                      type: "textarea",
                    },
                    model: {
                      value: _vm.params.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.params, "content", $$v)
                      },
                      expression: "params.content",
                    },
                  }),
                ],
                1
              ),
              _c("li"),
              _c(
                "li",
                [
                  _vm._m(2),
                  _c("el-input", {
                    attrs: { size: "small" },
                    model: {
                      value: _vm.params.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.params, "name", $$v)
                      },
                      expression: "params.name",
                    },
                  }),
                ],
                1
              ),
              _c("li", [
                _c(
                  "label",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.type,
                        expression: "type",
                      },
                    ],
                    staticClass: "common-tip",
                  },
                  [_vm._v("紧急程度：")]
                ),
                _c(
                  "div",
                  [
                    _c(
                      "el-select",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.type,
                            expression: "type",
                          },
                        ],
                        attrs: {
                          placeholder: "请选择",
                          size: "small",
                          clearable: "",
                        },
                        model: {
                          value: _vm.params.urgencyRank,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "urgencyRank", $$v)
                          },
                          expression: "params.urgencyRank",
                        },
                      },
                      _vm._l(_vm.urgencylevel, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("li", [
                _c(
                  "label",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.type,
                        expression: "type",
                      },
                    ],
                    staticClass: "common-tip",
                  },
                  [_vm._v("事件状态：")]
                ),
                _c(
                  "div",
                  [
                    _c(
                      "el-select",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.type,
                            expression: "type",
                          },
                        ],
                        attrs: {
                          placeholder: "请选择",
                          size: "small",
                          clearable: "",
                        },
                        model: {
                          value: _vm.params.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "status", $$v)
                          },
                          expression: "params.status",
                        },
                      },
                      _vm._l(_vm.eventstatus, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "li",
                [
                  this.params.type == 2
                    ? _c(
                        "label",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.type,
                              expression: "type",
                            },
                          ],
                          staticClass: "common-tip",
                        },
                        [_c("i", [_vm._v("*")]), _vm._v(" 不处置原因")]
                      )
                    : _vm._e(),
                  this.params.type == 2
                    ? _c("el-input", {
                        attrs: {
                          size: "small",
                          maxlength: "30",
                          "show-word-limit": "",
                          type: "textarea",
                        },
                        model: {
                          value: _vm.params.ignoreReason,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "ignoreReason", $$v)
                          },
                          expression: "params.ignoreReason",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c("li", [
                _vm._m(3),
                _c(
                  "div",
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择",
                          size: "small",
                          clearable: "",
                        },
                        model: {
                          value: _vm.params.reportUserId,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "reportUserId", $$v)
                          },
                          expression: "params.reportUserId",
                        },
                      },
                      _vm._l(_vm.reportArr, function (item) {
                        return _c("el-option", {
                          key: item.userId,
                          attrs: { label: item.realName, value: item.userId },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("li", [
                _vm._m(4),
                _c(
                  "div",
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "datetime",
                        placeholder: "选择日期时间",
                        "value-format": "yyyy-MM-dd HH:mm:ss",
                      },
                      model: {
                        value: _vm.params.happenTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.params, "happenTime", $$v)
                        },
                        expression: "params.happenTime",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "li",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.handleAttManage },
                    },
                    [_vm._v("选择附件")]
                  ),
                ],
                1
              ),
              _vm._l(_vm.currentFileList, function (file) {
                return _c("li", { key: file.id, staticClass: "item" }, [
                  _vm.isVideo(file.fullUrl)
                    ? _c("video", {
                        staticClass: "video",
                        attrs: {
                          src: file.fullUrl,
                          autoplay: "true",
                          loop: "true",
                        },
                      })
                    : _c("img", {
                        staticStyle: {
                          width: "200px",
                          margin: "5px",
                          height: "150px",
                        },
                        attrs: { src: file.fullUrl, alt: "" },
                      }),
                  _vm._v(" " + _vm._s(file.name) + " "),
                  _c("i", {
                    staticClass: "el-icon-close",
                    on: {
                      click: function ($event) {
                        return _vm.deleteImage(file)
                      },
                    },
                  }),
                ])
              }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "btns" },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.handleCancel } },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.handleConfirm },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("att-manage", {
        attrs: { fileList: _vm.fileList },
        on: {
          attManageSearch: _vm.attManageSearch,
          receiveFileUrl: _vm.receiveFileUrl,
        },
        model: {
          value: _vm.attDialogVisible,
          callback: function ($$v) {
            _vm.attDialogVisible = $$v
          },
          expression: "attDialogVisible",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "common-tip" }, [
      _c("i", [_vm._v("*")]),
      _vm._v("地点:"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "common-tip" }, [
      _c("i", [_vm._v("*")]),
      _vm._v("事件内容："),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "common-tip" }, [
      _c("i", [_vm._v("*")]),
      _vm._v("事件名称"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "common-tip" }, [
      _c("i", [_vm._v("*")]),
      _vm._v("上报人："),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "common-tip" }, [
      _c("i", [_vm._v("*")]),
      _vm._v("发生时间："),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }